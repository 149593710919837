@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

// HAIGHTS
$header-height: 3.5rem;

// COLORS
$first-color: #faa422;
$black-color: #333333;
$card-color: #e6e6e6;
$title-color: #1b1f22;
$text-color: #818c97;
$text-color-light: #dee0e0;
$body-color: #f7f7f8;
$btn-color: #243B55;
$white-color: #fff;

// FONTS
$mainFont: "Roboto", san-serif;
$fontXlg: 4rem;
$fontLg: 3rem;
$fontSlg: 1.5rem;
$fontMd: 1rem; //normal-font-size: 1rem;
$fontSm: .875rem; //small-font-size

// FONT WEIGHT
$font-bold: 700;

// Z INDEX
$z-tooltip: 10;
$z-fixed: 100;

// SHADOWS
$shadowOne: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
$shadowTwo: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
$shadowText: .1rem.1rem .1rem #333333;

// MARGINS
$mb-1: .5rem;
$mb-2: 1rem;
$mb-3: 1.5rem;
$mb-4: 2rem;
$mb-5: 2.5rem;
$mb-6: 3rem;