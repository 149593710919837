@use "./variables" as *;
@use "./mixins";

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
html {
    scroll-behavior: smooth;
    font-size: 100%;
    width: 100vw; 
    height: 100%; 
}
body {
    width: inherit; 
    min-height: inherit; 
    font-family: $mainFont;
    font-size: 16px;
    background-color: $body-color;
    color: $text-color;
    overflow-x: hidden;
}
img {
    display: block;
    max-width: 100%;
    height: auto;
}
h1 {
    font-size: 3rem;
    @include mixins.breakpoint-up(medium){
        font-size: 3.5rem;
    }
}
h2 {
    font-size: 2.5rem;
    @include mixins.breakpoint-up(medium){
        font-size: 2.8rem;
    }
}
h3 {
    font-size: 1.7rem;
    @include mixins.breakpoint-up(medium){
        font-size: 2rem;
    }
}
h4 {
    font-size: clamp(1.4rem, 1.4vw, 1.6rem);
}
p {
    font-size: clamp(1rem, 1vw, 1.5rem);
}
ul {
    list-style: none;
}
a {
    text-decoration: none;
}
img {
    max-width: 100%;
    max-height: 100%;
    display: block;
}
button {
    border: none;
    outline: none;
    cursor: pointer;
}

// REUSABLE COMPONENTS
.container {
    max-width: 64rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    @include mixins.breakpoint-up(xlarge){
        margin-left: auto;
        margin-right: auto;
    }
}
.grid {
    display: grid;
    gap: 1.5rem;
}
.section {
    padding: 4rem 0 2rem;

    @include mixins.breakpoint-up(medium){
        padding: 7rem 0 2rem;
    }
}
.section__title {
    position: relative;
    padding-left: 1.25rem;
    margin-bottom: $mb-4;

    &::before {
        content: "";
        position: absolute;
        top: .5rem;
        left: 0;
        width: 3px;
        height: 1.2rem;
        background-color: $first-color;
    }
}

// BUTTON
.button {
    display: inline-box;
    background: linear-gradient(to right, #243B55, #141E30);
    color: $white-color;
    padding: 1rem 1.75rem;
    border-radius: .25rem;
    font-size: $fontMd;
    text-transform: uppercase;
    position: relative;
    box-shadow: $shadowOne;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(255,255,255,0.1);
        transition: all 0.3s;
    }
    &:hover::before {
        opacity: 0 ;
        transform: scale(0.5,0.5);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        transition: all 0.3s;
        border: 1px solid rgba(255,255,255,0.5);
        transform: scale(1.2,1.2);
    }

    &:hover::after {
        opacity: 1;
        transform: scale(1,1);
    }
}