@use "../../style/mixins";
@use "../../style/variables" as *;

.banner {
    gap: 0;

    @include mixins.breakpoint-up(medium) {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        align-items: stretch;
        position: relative;
    }

    &__container {
        padding: 5rem 0 2.5rem;
        background: #141E30;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #243B55, #141E30);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #243B55, #141E30); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

        @include mixins.breakpoint-up(mlarge) {
            padding-top: 10rem;
        }
    }

    .banner__content {
        position: relative;

        @include mixins.breakpoint-up(medium) {
            position: initial;
        }

        @include mixins.breakpoint-up(xlarge) {
            max-width: 25rem;
            margin-right: 7rem;
            margin-left: auto;
        }

        .banner__title,
        .banner__data-title {
            color: $white-color;
        }

        .banner__title {
            margin-bottom: 1.5rem;
            text-transform: capitalize;
            
            & span {
                color: $first-color;
            }

            @include mixins.breakpoint-up(mlarge) {
                bottom: 2rem;
                line-height: 150%;
            }
        }

        .banner__description {
            margin-bottom: 1rem;
            text-transform: capitalize;

            @include mixins.breakpoint-up(mlarge) {
                margin-bottom: 3rem;
            }
        }

        .banner__data {
            border-top: 1px solid $text-color;
            padding-top: 1rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 1.5rem;

            @include mixins.breakpoint-up(mlarge) {
                padding-top: 3rem;
            }

            &-number {
                color: $first-color;
                margin-bottom: 1rem;
                text-transform: capitalize;
            }

            &-title {
                margin-bottom: .5rem;
                text-transform: capitalize;
            }

            &-description {
                line-height: 120%;

                @include mixins.breakpoint-up(medium){
                    line-height: 160%;
                }
            }
        }

        .banner__scroll {
            position: absolute;
            width: 6.25rem;
            bottom: -7rem;
            left: 0;
            right: 0;
            margin: 0 auto;

            @include mixins.breakpoint-up(medium) {
                bottom: 0;
                top: 0;
                margin: auto;
            }

            @include mixins.breakpoint-up(mlarge) {
                width: 8.125rem;
            }
        }
    }

    .banner__img {
        object-fit: cover;
    }
}