@use "../../style/mixins";
@use "../../style/variables" as *;

.header {
    width: 100%;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-fixed;

    &.scroll-header {
        background-color: $body-color;
        box-shadow: 0 1px 4px #2825251a;

        .nav__logo {
            color: $btn-color;
        }

        .nav__toggle .toggle1 {
            display: none;
        }

        .nav__toggle .toggle2 {
            display: block;
        }

        @include mixins.breakpoint-up(medium){
            .nav__link {
                color: $btn-color !important;
            }
        }
    }

    .nav {
        height: calc($header-height + .5rem);
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mixins.breakpoint-up(medium){
            height: calc($header-height + 1rem);
        }

        &__logo {
            display: flex;
            align-items: center;
            column-gap: .5rem;
            color: $white-color;
            font-weight: $font-bold;
            text-transform: capitalize;

            @include mixins.breakpoint-up(large) {
                font-size: 1.5rem
            }
        }

        &__toggle {
            display: inline-flex;
            cursor: pointer;
        }

        &__toggle,
        &__close {
            @include mixins.breakpoint-up(medium){
                display: none;
            }
        }

        &__toggle {
            .toggle2 {
                display: none;
            }
        }
        
        .nav__menu {
            
            @include mixins.breakpoint-down(medium) {
                position: fixed;
                top: 0;
                right: -100%;
                width: 100%;
                height: 100%;
                transition: .5s;
                background: #141E30;  /* fallback for old browsers */
                background: -webkit-linear-gradient(to right, #243B55, #141E30);  /* Chrome 10-25, Safari 5.1-6 */
                background: linear-gradient(to right, #243B55, #141E30); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            }

            &.show-menu {
                right: 0;
            }

            .nav__list {
                display: flex;
                flex-direction: column;
                row-gap: 4rem;
                text-align: center;
                padding-top: 9rem;

                @include mixins.breakpoint-up(medium) {
                    padding-top: 0;
                    flex-direction: row;
                    column-gap: 4rem;
                }

                .nav__item {

                    .nav__link {
                        text-transform: uppercase;
                        color: $text-color-light;
                        font-size: 1rem;
                        font-weight: 700;
                        letter-spacing: 2px;
                        transition: .3s;
        
                        @include mixins.breakpoint-up(medium) {
                            position: relative;
                            font-size: 1.2rem;
                            letter-spacing: normal;
        
                            &::after {
                                content: "";
                                position: absolute;
                                width: 1px;
                                height: 1rem;
                                background-color: $text-color;
                                transform: translateX(1.25rem);
                                top: 0;
                                bottom: 0;
                                margin: 0 auto;
                            }

                            &:last-child::after {
                                display: none;
                            }
                        } 

                        &:active {
                            color: $first-color;
                        }
    
                        .active-link {
                            color: $first-color;
                        }
                    }
                }
            }

            .nav__close {
                position: absolute;
                top: 1.5rem;
                right: 1.5rem;
                cursor: pointer;
            }
        }
    }
}

