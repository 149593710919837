@use "../../style/mixins";
@use "../../style/variables" as *;

.testimonials {
    position: relative;

    @include mixins.breakpoint-up(large) {
        padding-bottom: 5rem;
    }

    &__container {
        padding-bottom: 2rem;

        .testimonials__content {
            gap: 2.5rem;
            justify-content: center;
            align-items: center;
            position: relative;
            padding-bottom: 2rem;
    
            @include mixins.breakpoint-up(medium){
                padding-bottom: 4rem;
            }

            .slide__container {
                display: none;
                padding-bottom: 2rem;
    
                &.active-slide {
                    display: block;
                }
    
                .slide {
                    border-radius: .5rem;
                    background-color: $white-color;
                    box-shadow: $shadowOne;
                    max-width: 30rem;
                    max-height: 20rem;
                    padding: 1rem;
                    position: relative;
                    transform-style: preserve-3d;
                    animation: slideRotate .4s linear;
    
                    & > * {
                        animation: slideScale .2s linear .4s backwards;
                    }
    
                    @include mixins.breakpoint-up(large) {
                        max-height: 25rem;
                        padding: 1rem 2rem;
                    }
        
                    @keyframes slideScale {
                        0% {
                            opacity: 0;
                            transform: scale(.5);
                        }
                    }
    
                    .slide__quote {
                        position: absolute;
                        top: 1rem;
                        right: 1rem;
                        z-index: $z-tooltip;
                    }
    
                    .user {
                        padding-top: 1rem;
                        display: flex;
                        align-items: flex-start;
                        column-gap: .3rem;
    
                        .user__img {
                            width: 6.25rem;
                            height: 6.25rem;
                            object-fit: cover;
                            margin: 0 1rem 1rem 0;
                        }
    
                        .user__info {
                            
                            & h4 {
                                color: $text-color;
                                text-transform: capitalize;
                                margin-bottom: $mb-1;
                            }
                            
                            .user__stars {
                                display: flex;
                                align-items: center;
                                column-gap: .1rem;
                            }
                        }
                    }

                    .user__description {
                        color: $text-color;
                        font-size: $fontSm;
                        font-style: italic;
                        padding: .5rem 0;
                        line-height: 150%;
                        width: 90%;
                    }
                }
    
                @keyframes slideRotate {
                    0% {
                        transform: rotateX(180deg);
                    }
                }
            }
        }
    }

    .prev__slide,
    .next__slide {
        position: absolute;
        bottom: 10%;
        height: 3.125rem;
        width: 3.125rem;
        background: transparent;
        cursor: pointer;
        border-radius: 50%;

        @include mixins.breakpoint-up(large) {
            top: 45%;
        }
    }

    .prev__slide {
        left: 30%;

        @include mixins.breakpoint-up(large) {
            left: 15%;
        }
    }

    .next__slide {
        right: 30%;

        @include mixins.breakpoint-up(large) {
            right: 15%;
        }
    }
}